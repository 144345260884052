import React, { useState, useEffect } from 'react';
import './DragAndDrop.css';
import parse from 'html-react-parser';
import { CustomQuestion } from '../../theme/QuestionLabel';
import { CustomInstruction } from '../../theme/InstructionLabel';
import { CustomResponse } from '../../theme/ResponseLabel';
import OtherTextInput from '../../CommonComponents/OtherTextInput';
import { useDispatch, useSelector } from "react-redux";
import { setCurrentQuestion } from "../../../redux/Reducers/currentQuestionSlice";

const DragAndDrop = ({ question }) => {
  const dispatch = useDispatch();
  const responses = useSelector((store) => store.responses || {});
  const initialResponse = responses[question?.questionId] || { droppedItems: [], otherText: {} };
  
  const [items, setItems] = useState(
    (question?.options || []).map((option) => ({ code: option.code, label: option.label }))
  );
  const [droppedItems, setDroppedItems] = useState(initialResponse.droppedItems || []);
  const [otherText, setOtherText] = useState(initialResponse.otherText || {});
  const [removedExclusiveItems, setRemovedExclusiveItems] = useState(new Set());
  const [touchStartTimestamp, setTouchStartTimestamp] = useState(0);
const [touchStartX, setTouchStartX] = useState(0);
const [touchStartY, setTouchStartY] = useState(0);
const DRAG_DURATION_THRESHOLD = 250; // in ms
const DRAG_DISTANCE_THRESHOLD = 10; // in pixels


  useEffect(() => {
    if (responses && responses[question.questionId]) {
      const response = responses[question.questionId];
      if (Array.isArray(response)) {
        const dropped = response.map(res => res.code);
        const _otherText = {};
        response.forEach(res => {
          if (res.Other_text) {
            _otherText[res.code] = res.Other_text;
          }
        });
        setDroppedItems(dropped);
        setOtherText(_otherText);
        const remainingItems = question.options
          .map(option => ({ code: option.code, label: option.label }))
          .filter(item => !dropped.includes(item.code));
        setItems(remainingItems);
      }
    }
  }, [responses, question.questionId, question.options]);

  useEffect(() => {
    const currentOptions = question.options.filter(option => droppedItems.includes(option.code));
    const response = currentOptions.map(option => ({
      code: option.code,
      ...(option.isOther && otherText[option.code] && { Other_text: otherText[option.code] })
    }));
    if (droppedItems.length || Object.keys(otherText).length) {
      dispatch(setCurrentQuestion({ id: question.questionId, response }));
    }
    const updatedItems = question.options
      .map(option => ({ code: option.code, label: option.label }))
      .filter(item => !droppedItems.includes(item.code));
    setItems(updatedItems);
  }, [droppedItems, otherText, dispatch, question.questionId, question.options]);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("draggedItem", index);
    e.currentTarget.style.opacity = "0.4";
  };

  const handleTouchStart = (e, index) => {
    setTouchStartTimestamp(Date.now());
    setTouchStartX(e.touches[0].clientX);
    setTouchStartY(e.touches[0].clientY);
    e.currentTarget.style.opacity = "0.4";
    e.target.dataset.draggedItem = index;
};

  const handleDropLogic = (draggedItem) => {
    const draggedOption = question.options.find(
      (option) => option.code === draggedItem.code
    );

    if (draggedOption.isExclusive) {
      setDroppedItems([draggedItem.code]);
      setItems(
        question.options
          .map((option) => ({ code: option.code, label: option.label }))
          .filter((item) => item.code !== draggedItem.code)
      );
      setRemovedExclusiveItems(new Set([draggedItem.code]));
    } else {
      const nonExclusiveItems = droppedItems.filter(
        (code) => !question.options.find((option) => option.code === code).isExclusive
      );
      setDroppedItems([...nonExclusiveItems, draggedItem.code]);
      setItems((prevItems) => {
        const updatedItems = prevItems.filter((item) => item.code !== draggedItem.code);
        const itemsToRestore = Array.from(removedExclusiveItems).filter(
          (code) => !updatedItems.some(item => item.code === code)
        );
        return [...updatedItems, ...itemsToRestore.map(code => question.options.find(option => option.code === code))];
      });
      setRemovedExclusiveItems(new Set());
    }

    if (draggedOption.isOther) {
      setOtherText(prev => ({ ...prev, [draggedItem.code]: '' }));
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const draggedIndex = e.dataTransfer.getData("draggedItem");
    const draggedItem = items[draggedIndex];
    handleDropLogic(draggedItem);
  };

  const handleTouchEnd = (e) => {
    const touchDuration = Date.now() - touchStartTimestamp;
    const touchEndX = e.changedTouches[0].clientX;
    const touchEndY = e.changedTouches[0].clientY;
    const touchDistance = Math.sqrt(
        Math.pow(touchEndX - touchStartX, 2) + Math.pow(touchEndY - touchStartY, 2)
    );

    // Get the bounding box of the drop box
    const dropBox = document.querySelector('.drop-box');
    const dropBoxBounds = dropBox.getBoundingClientRect();

    // Check if the touch ended within the drop box boundaries
    const isWithinDropBox =
        touchEndX >= dropBoxBounds.left &&
        touchEndX <= dropBoxBounds.right &&
        touchEndY >= dropBoxBounds.top &&
        touchEndY <= dropBoxBounds.bottom;

    // Only proceed with drop logic if within the drop box and duration/distance thresholds are exceeded
    if (isWithinDropBox && (touchDuration > DRAG_DURATION_THRESHOLD || touchDistance > DRAG_DISTANCE_THRESHOLD)) {
        const draggedIndex = e.target.dataset.draggedItem;
        if (draggedIndex !== undefined) {
            const draggedItem = items[draggedIndex];
            handleDropLogic(draggedItem);
        }
    }

    // Reset item opacity
    e.currentTarget.style.opacity = "1";
};



  const handleRemoveDroppedItem = (itemCode, e) => {
    e.preventDefault();
    e.stopPropagation();
  
    const updatedDroppedItems = droppedItems.filter(code => code !== itemCode);
    setDroppedItems(updatedDroppedItems);
  
    const itemOption = question.options.find(option => option.code === itemCode);
  
    if (itemOption.isExclusive) {
      setRemovedExclusiveItems(prevRemoved => {
        const newRemoved = new Set(prevRemoved);
        newRemoved.delete(itemCode);
        return newRemoved;
      });
      setItems(prevItems => {
        const itemsToRestore = Array.from(removedExclusiveItems).filter(code => code !== itemCode);
        return [...prevItems, itemOption, ...itemsToRestore.map(code => question.options.find(option => option.code === code))];
      });
    } else {
      setItems(prevItems => [...prevItems, itemOption]);
    }
  
    if (itemOption.isOther) {
      setOtherText(prev => {
        const updatedText = { ...prev };
        delete updatedText[itemCode];
        return updatedText;
      });
    }
  
    if (updatedDroppedItems.length === 0) {
      if (question.required) {
        dispatch(setCurrentQuestion({ id: question.questionId, response: null }));
      } else {
        dispatch(setCurrentQuestion({ id: question.questionId, response: {} }));
      }
    } else {
      const currentOptions = question.options.filter(option => updatedDroppedItems.includes(option.code));
      const response = currentOptions.map(option => ({
        code: option.code,
        ...(option.isOther && otherText[option.code] && { Other_text: otherText[option.code] })
      }));
  
      dispatch(setCurrentQuestion({ id: question.questionId, response }));
    }
  };
  
  const handleDragEnd = (e) => {
    e.currentTarget.style.opacity = "1";
  };

  const handleOtherTextChange = (code, event) => {
    const { value } = event.target;
    setOtherText(prev => ({ ...prev, [code]: value }));
  };

  return (
    <div>
      <div className="flex flex-col">
        {question?.questionText && (
          <CustomQuestion
            id={`${question.questionId}_text`}
            key={`${question.questionId}_text`}
            text={parse(question.questionText)}
          />
        )}
        {question?.questionInstruction && (
          <CustomInstruction
            id={`${question.questionId}_instruction`}
            key={`${question.questionId}_instruction`}
            text={parse(question.questionInstruction)}
          />
        )}
      </div>
      <div className="drag-container flex">
        <CustomResponse className="flex-wrap"
          id={`${question.questionId}_response`}
          key={`${question.questionId}_response`}
        >
          {Array.isArray(items) && items.length > 0 ? (
            items.map((item, index) => (
              <div
                key={index}
                className="drag-item self-center"
                draggable
                onDragStart={(e) => handleDragStart(e, index)}
                onTouchStart={(e) => handleTouchStart(e, index)}
                onTouchEnd={handleTouchEnd}
                onDragEnd={handleDragEnd}
                onClick={(e) => e.stopPropagation()}
              >
                {item.label}
              </div>
            ))
          ) : (
            <p>No items available</p>
          )}
        </CustomResponse>
      </div>
      <div className="drop-box"
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={(e) => e.preventDefault()}
        onTouchEnd={handleTouchEnd}
      >
        <CustomResponse className='flex flex-wrap'>
          {Array.isArray(droppedItems) && droppedItems.length > 0 ? (
            droppedItems.map((code) => {
              const itemOption = (question.options || []).find((option) => option.code === code);
              if (!itemOption) return null;

              return (
                <div key={itemOption.code}
                  className="dropped-item"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  {itemOption.isOther && droppedItems.includes(itemOption.code) ? (
                    <OtherTextInput
                      id={`${question.questionId}_other_${itemOption.code}`}
                      key={`${question.questionId}_other_${itemOption.code}`}
                      name="otherText"
                      value={otherText[itemOption.code] || ''}
                      onChange={(event) => handleOtherTextChange(itemOption.code, event)}
                      placeholder={itemOption.label}
                      className="w-100"
                    />
                  ) : (
                    <span>{itemOption.label}</span>
                  )}
                  <button
                    type="button"
                    className="remove-button ml-2"
                    onClick={(e) => handleRemoveDroppedItem(itemOption.code, e)}
                  >
                    x
                  </button>
                </div>
              );
            })
          ) : (
            <p>Drop items here</p>
          )}
        </CustomResponse>
      </div>
    </div>
  );
};

export default DragAndDrop;
