import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentQuestion } from '../../../redux/Reducers/currentQuestionSlice';
import OtherTextInput from '../../CommonComponents/OtherTextInput';
import parse from 'html-react-parser';
import { CustomQuestion } from '../../theme/QuestionLabel';
import { CustomInstruction } from '../../theme/InstructionLabel';
import { CustomResponse } from '../../theme/ResponseLabel';

const DropDowncomponent = ({ question }) => {
  const storedResponses = useSelector(store => store.responses);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [otherText, setOtherText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (question && question.options) {
      const options = question.options.map(option => ({
        value: option.code,
        label: option.label,
        isOther: option.isOther
      }));
      setFilteredOptions(options);
    }
  }, [question]);

  useEffect(() => {
    if (storedResponses && storedResponses[question.questionId]) {
      let _otherText = storedResponses[`${question.questionId}_other`] ? storedResponses[`${question.questionId}_other`] : '';
      setOtherText(_otherText);
      const response = storedResponses[question.questionId];

      if (question.isMulti) {
        const options = response.map(code => {
          const option = filteredOptions.find(opt => opt.value === (code.code || code));
          if (option && option.isOther && code.Other_text) {
            setOtherText(code.Other_text);
          }
          return option;
        }).filter(option => option);
        setSelectedOptions(options);
        checkIfOtherSelected(options);
      } else {
        const option = filteredOptions.find(opt => opt.value === (response.code || response));
        if (option && option.isOther && response.Other_text) {
          setOtherText(response.Other_text);
        }
        setSelectedJob(option);
        checkIfOtherSelected([option]);
      }
    }
  }, [storedResponses, question, filteredOptions]);

  useEffect(() => {
    let response;

    if (question.isMulti) {
      response = selectedOptions.length > 0
        ? selectedOptions.map(option => option.isOther ? { code: option.value, Other_text: otherText } : option.value)
        : undefined; // Set to undefined when no selection is made
    } else {
      response = selectedJob && selectedJob.isOther
        ? { code: selectedJob.value, Other_text: otherText }
        : selectedJob ? selectedJob.value : null;
    }

    dispatch(setCurrentQuestion({ id: question.questionId, response }));
  }, [selectedOptions, selectedJob, otherText, isOtherSelected, dispatch, question.questionId, question.isMulti]);

  const handleSelectChange = (options) => {
    setSelectedOptions(options || []); // Ensure empty array if no options selected
    checkIfOtherSelected(options || []);
  };

  const handleJobChange = (job) => {
    setSelectedJob(job);
    checkIfOtherSelected([job]);
  };

  const checkIfOtherSelected = (options) => {
    const isOther = options.some(option => option && option.isOther);
    setIsOtherSelected(isOther);
    if (!isOther) {
      setOtherText('');
    }
  };

  const handleOtherTextChange = (e) => {
    const value = e.target.value;
    setOtherText(value);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
      // ? 'var(--selected-bg-color)' // Background color for selected option
      // : state.isFocused
        ? 'var(--hover-bg-color)'    // Background color for hovered option
        : null,                      // No background color for unselected options
      color: state.isSelected ? 'white' : 'black', // Text color for selected/unselected
      '&:hover': {
        backgroundColor: 'var(--hover-bg-color)', // Hover color for any option
        color: 'white', // Text color on hover
      },
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? 'var(--hover-bg-color)' : provided.borderColor,
      boxShadow: state.isFocused ? '0 0 0 1px var(--hover-bg-color)' : null,
      '&:hover': {
        borderColor: 'var(--hover-bg-color)',
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'white', // Text color for selected option
      backgroundColor: 'var(--selected-bg-color)', // Background color for selected option
      padding: '2px 4px',
      borderRadius: '4px',
    }),
  };


  return (
    <>
      <div className="flex flex-col">
        {question.questionText && (
          <CustomQuestion
            id={`${question.questionId}_text`}
            key={`${question.questionId}_text`}
            text={parse(question.questionText)}
          />
        )}

        {question.questionInstruction && (
          <CustomInstruction
            id={`${question.questionId}_instruction`}
            key={`${question.questionId}_instruction`}
            text={parse(question.questionInstruction)}
          />
        )}
      </div>

      <div className="mt-4 flex-col">
        <CustomResponse>
          <Select
            id={`${question.questionId}_select`}
            key={`${question.questionId}_select`}
            value={question.isMulti ? selectedOptions : selectedJob}
            onChange={question.isMulti ? handleSelectChange : handleJobChange}
            options={filteredOptions}
            className='button-width'
            isMulti={question.isMulti}
            isSearchable={question.isSearchable}
            styles={customStyles}
          />

          {isOtherSelected && (
            <OtherTextInput
              id={`${question.questionId}_other`}
              key={`${question.questionId}_other`}
              value={otherText}
              onChange={handleOtherTextChange}
              placeholder={
                question.isMulti
                  ? selectedOptions.find(option => option.isOther)?.label || '' // Show label of "Other" if selected
                  : selectedJob && selectedJob.isOther
                    ? selectedJob.label // Show label of "Other" for single select
                    : ''
              }
            />
          )}
        </CustomResponse>
      </div>
    </>
  );
};

export default DropDowncomponent;
