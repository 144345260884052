import React, { useState, useEffect } from "react";
import Slider from "@mui/material/Slider";
import { setCurrentQuestion } from "../../../redux/Reducers/currentQuestionSlice";
import { useDispatch, useSelector } from "react-redux";
import OtherTextInput from "../../CommonComponents/OtherTextInput";
import parse from "html-react-parser";
import randomizeOptions from "../../CommonComponents/Randomization";
import { CustomQuestion } from "../../theme/QuestionLabel";
import { CustomInstruction } from "../../theme/InstructionLabel";
import { CustomResponse } from "../../theme/ResponseLabel";

const TextSliderQuestion = ({ question }) => {
  const {
    options,
    questionId,
    questionText,
    questionInstruction,
    randomization,
    orientation = "horizontal",
  } = question;
  const [randomizedOptions, setRandomizedOptions] = useState([]);
  const numberOfOptions = randomizedOptions.length;
  const textMarks = randomizedOptions.map((option, index) => ({
    value: (100 / (numberOfOptions - 1)) * index,
    label: parse(option.label),
  }));

  const responses = useSelector((store) => store.responses);
  const [storedResponse, setStoredResponse] = useState(undefined);
  const [otherText, setOtherText] = useState("");
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [otherCode, setOtherCode] = useState(""); // State to track the code for the other option
  const dispatch = useDispatch();

  useEffect(() => {
    if (randomization) {
      setRandomizedOptions(randomizeOptions(options));
    } else {
      setRandomizedOptions(options);
    }
  }, [options, randomization]);

  useEffect(() => {
    if (responses && responses[questionId] !== undefined) {
      const response = responses[questionId];
      const otherTextResponse = responses[`${questionId}_other`] || "";
      setOtherText(otherTextResponse);

      if (typeof response === "object") {
        const index = randomizedOptions.findIndex(
          (option) => option.code === response.code
        );
        setStoredResponse((100 / (numberOfOptions - 1)) * index);
        setIsOtherSelected(
          response.code && randomizedOptions[index]?.isOther === true
        );
        setOtherCode(response.code);
      } else {
        const index = randomizedOptions.findIndex(
          (option) => option.code === response
        );
        setStoredResponse(
          index >= 0 ? (100 / (numberOfOptions - 1)) * index : undefined
        );
        setIsOtherSelected(
          randomizedOptions[index]?.isOther === true && otherTextResponse !== ""
        ); // If there is other text, set isOtherSelected to true
        setOtherCode(otherTextResponse !== "" ? response : ""); // Set otherCode if otherTextResponse is present
      }
    } else {
      setStoredResponse(undefined); // Reset stored response if no response is available
      setOtherText(""); // Clear other text input
      setIsOtherSelected(false); // Reset isOtherSelected
      setOtherCode(""); // Reset otherCode
    }
  }, [responses, questionId, randomizedOptions, numberOfOptions]);

  useEffect(() => {
    if (storedResponse !== undefined) {
      const index = Math.round(storedResponse / (100 / (numberOfOptions - 1)));
      const selectedOption = randomizedOptions[index];
      const response = isOtherSelected
        ? { code: selectedOption.code, Other_text: otherText }
        : selectedOption.code;
      dispatch(
        setCurrentQuestion({
          id: questionId,
          response,
        })
      );
    }
  }, [
    storedResponse,
    dispatch,
    questionId,
    isOtherSelected,
    otherText,
    randomizedOptions,
    numberOfOptions,
  ]);

  const handleSliderChange = (e, value) => {
    setStoredResponse(value);
    const index = Math.round(value / (100 / (numberOfOptions - 1)));
    const selectedOption = randomizedOptions[index];

    //Fallback to primary color if no specific color is set for the selected option
    const selectedColor = selectedOption?.color || getComputedStyle(document.documentElement).getPropertyValue('--primary-color').trim();
    // Dynamically update CSS variables for slider, border, and background color
    document.documentElement.style.setProperty('--selected-slider-color', selectedColor); // Slider color
    document.documentElement.style.setProperty('--selected-border-color', selectedColor); // Border color
    document.documentElement.style.setProperty('--selected-bg-color', selectedColor); // Background color



    if (selectedOption?.isOther) {
      // If "other" option is selected, set it as selected and preserve the code
      setIsOtherSelected(true);
      setOtherCode(selectedOption.code);
      // If the new "other" option code is different from the current one, clear the text
      if (selectedOption.code !== otherCode) {
        setOtherText("");
      }
    } else {
      // If a regular option is selected, reset the "other" state
      setIsOtherSelected(false);
      setOtherText("");
      setOtherCode("");
    }
  };

  const handleOtherTextChange = (e) => {
    const { value } = e.target;
    setOtherText(value);
  };

  return (
    <>
      <div className="flex flex-col">
        {questionText && (
          <CustomQuestion
            id={`${questionId}_text`}
            key={`${questionId}_text`}
            text={parse(questionText)}
          />
        )}
        {questionInstruction && (
          <CustomInstruction
            id={`${questionId}_instruction`}
            key={`${questionId}_instruction`}
            text={parse(questionInstruction)}
          />
        )}
      </div>

      <CustomResponse className="vertical-slider-container mt-4">
        <Slider
          id={`${questionId}_slider`}
          key={`${questionId}_slider`}
          value={storedResponse ?? 0}
          onChange={handleSliderChange}
          marks={textMarks}
          step={null}
          orientation={orientation}
        />
        {isOtherSelected && (
          <OtherTextInput
            className="ml-0 mt-4"
            id={`${questionId}_${otherCode}_other`}
            key={`${questionId}_${otherCode}_other`}
            value={otherText}
            onChange={handleOtherTextChange}
          />
        )}
      </CustomResponse>
    </>
  );
};

export default TextSliderQuestion;